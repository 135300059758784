import axios from 'axios'
import {  Toast } from "vant";
import {  decode } from 'js-base64';
import {cf} from '../config/config'
import md5 from 'js-md5'


const service = axios.create({
  baseURL: cf.baseURL,
  timeout: 3000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
})

// Request interceptors
service.interceptors.request.use(
		config => {
			let t = Date.now();
			config.headers['Sec'] = md5(t + cf.secret);
			config.headers['Time'] = t;
			return config;
		},
		error => {
			console.log(error);
			return Promise.reject();
		}
)

// Response interceptors
service.interceptors.response.use(
  (response) => {
    const res = response.data
    if (res.code === 200) {
      // 解密
      if(typeof res.data === 'string' && res.data) {
        const str = res.data.substring(20, res.data.length - 12)
        return JSON.parse(decode(str))
      }
      return res.data
    }
    Toast(res.message);
    return Promise.reject(new Error(res.message || 'Error'))
  },
  (error) => {
    if (error.message.includes('timeout')) {

    } else {

    }
    return Promise.reject(error)
  }
)

export default service
