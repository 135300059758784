import request from '@/utils/request'
import Qs from 'qs';

// 获取验证码
export const getCode = (params) => request.post('/user/send_sms', Qs.stringify(params))

// 登录
export const login = (params) => request.post('/user/login', Qs.stringify(params))

// 用户信息
export const info = (params) => request.get('/user/info', {params})

// 动态信息
export const dynamic = (params) => request.get('/dynamic', {params})
